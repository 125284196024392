.profile-lato {
  font-family: Lato;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  text-align: center;
}

.settings-btn {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  padding: "10px 0px 10px 0px";
  border-style: none;
}

@media screen and (max-width: 768px) {
  .profile-lato {
    font-size: 16px;
  }
  .settings-btn {
    font-size: 12px;
  }
}
