.file-submit-box {
  height: 50vh;
  border-width: 2px;
  border-style: dashed;
  border-color: black;
  border-radius: 10px;
}

.assingment-submit-box {
  border: 2px solid #00000014;
  box-shadow: 2px 2px 10px 0px #00000014;
  padding: 10px;
  border-radius: 10px;
  width: 40%;
}

.remove {
  color: #ff0000;
}

.replace {
  color: #3970ff;
}

@media screen and (max-width: 768px) {
  .assingment-submit-box {
    border: 2px solid #00000014;
    box-shadow: 2px 2px 10px 0px #00000014;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
  }
}
