.card {
  background-color: #fcfcfc;
  background-image: url("../../../images/cardbackground.png");
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 2px hwb(0 96% 3%);
  border-color: #00000014#e7e3e3;
  border-width: 2px;
  border-style: solid;
}

.background-img {
  background-image: url("../../../images/cardbackground.png");
}

.text-lato {
  font-family: Lato;
  line-height: 19.2px;
  text-align: left;
}

.text-title {
  font-size: 24px;
  font-weight: 700;
}

.text-sub-title {
  font-size: 16px;
  font-weight: 700;
}

.text-medium {
  font-size: 14px;
  font-weight: 700;
}

.text-ash-medium {
  font-size: 16px;
  color: #000000b2;
}

.line-height {
  line-height: 4.5rem;
}

@media screen and (max-width: 768px) {
  .text-title {
    font-size: 16px;
    font-weight: 700;
  }
  .text-sub-title {
    font-size: 12px;
    font-weight: 700;
  }
  .line-height {
    line-height: 10px;
  }
  .text-ash-medium {
    font-size: 12px;
    color: #000000b2;
  }
}
