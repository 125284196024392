@tailwind base;
@tailwind components;
@tailwind utilities;
  
:root {
  --main: #7fdb80;
  --overlay: rgba(0, 0, 0, 0.8); /* background color overlay for alert dialogs, modals, etc. */

  --bg: #E0E7F1;
  --bw: #fff; /* white and secondary black e.g. utility class variant would be: bg-[#fff] dark:bg-[#212121] */
  --blank: #000; /* blank black and white */
  --border: #000;
  --text: #000;
  --mtext: #000; /* text that is placed on background with main color e.g. text color of default button */
  --ring: #000;
  --ring-offset: #fff;
  --btn-green:#7BF27C;
  --btn-pink: #FF40A5;

  --border-radius: 6px;
  --box-shadow-x: 6px;
  --box-shadow-y: 6px;
  --reverse-box-shadow-x: -6px;
  --reverse-box-shadow-y: -6px;
  --base-font-weight: 500;
  --heading-font-weight: 700;

  --shadowgreen: var(--box-shadow-x) var(--box-shadow-y) 0px 0px var(--btn-green);
  --shadowpink: var(--box-shadow-x) var(--box-shadow-y) 0px 0px var(--btn-pink);
}

.dark {
  --bg: #2c312b;
  --bw: #212121;
  --blank: #fff;
  --border: #000;
  --text: #e6e6e6;
  --mtext: #000;
  --ring: #fff;
  --ring-offset: #000;

  --shadow: var(--box-shadow-x) var(--box-shadow-y) 0px 0px var(--border);
}