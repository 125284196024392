.img {
  position: relative;
}

.button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.line-height {
  line-height: 5.5rem;
}

@media screen and (max-width: 768px) {
  .line-height {
    line-height: 30px;
  }
}
