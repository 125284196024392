.welcome-msg {
  width: 97%;
  height: 80px;
  top: 136px;
  left: 46px;
  padding: 24px 10px 24px 32px;
  gap: 16px;
  border-radius: 16px;
  opacity: 0px;
  background-color: #151a1e;
  color: white;
}

.text-lato {
  font-family: "Lato", "san-serif";
  font-weight: 700;
  line-height: 28.8px;
  text-align: center;
}

.btn {
  width: 15%;
  height: 44px;
  border-width: 1.5px;
  border-color: #151a1e;
  text-wrap: wrap;
}

.navigation-dropdown {
  display: none;
}
@media screen and (max-width: 768px) {
  .navigation-buttons {
    display: none;
  }
  .navigation-dropdown {
    display: block;
  }
}
