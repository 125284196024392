.text-lato {
  font-family: "Lato", "san-serif";
}

.background-dark-image {
  background-image: url("./images/background.png");
}

.text-pop {
  font-family: "poppins", "san-serif";
}
