:root {
  --border-background: #7bf27c;
  --border-color: black;
}

.live-div {
  font-family: "poppins";
  font-weight: 500;
  font-size: 16px;
  border: 2px solid var(--border-color);
  padding: 4px 20px 4px 20px;
  border-radius: 14px;
  box-shadow: 5px 10px 3px 0px var(--border-background);
}

.live-div:hover {
  cursor: pointer;
}

.left-course-bar {
  width: 35%;
}

.right-course-bar {
  width: 60%;
}

.course-box {
  border: 2px solid #00000014;
  box-shadow: 2px 2px 10px 0px #00000014;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.text-poppins-ash {
  font-family: "poppins";
  font-weight: 500;
  font-size: 16px;
  color: #00000099;
}

.topics-btn {
  border: none;
  color: #3970ff;
  font-family: "poppins";
  font-weight: 600;
}
.active-div-tab {
  display: none;
}
@media screen and (max-width: 768px) {
  .left-course-bar {
    width: 100%;
  }
  .right-course-bar {
    width: 100%;
    margin-top: 10px;
    display: none;
  }
  .active-div-tab {
    display: block;
  }
}
