.btn-active-course-text {
  font-size: "14px";
  font-family: "poppins", "san-serif";
  font-weight: 600;
}

.active-box-heading {
  font-family: "lato", "san-serif";
  font-weight: 600;
  color: #000000b2;
  font-size: 12px;
}
.active-box-subheading {
  font-family: "lato", "san-serif";
  font-weight: 600;
  color: #000000b2;
  font-size: 18px;
}

.btn-active {
  font-family: "lato", "san-serif";
  font-weight: 600;
}

.active-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .active-box-subheading {
    font-size: 10px;
    font-family: "lato", "san-serif";
    font-weight: 600;
    color: #000000b2;
  }

  .active-lato {
    font-family: "lato", "san-serif";
    font-weight: 600;
    color: #000000b2;
    font-size: 10px;
  }
}
